import { createInstance } from "@/lib/fn";
import BaseResourceHttp from "@/lib/resourceHttp";
class User extends BaseResourceHttp {
    baseURL() {
        return "user";
    }
    identifier(data) {
        return this.request("POST", this.url("identifier"), {
            data,
        });
    }
}
export const userHttp = createInstance(User);
export default userHttp;
